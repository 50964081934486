/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
const newsDomain = process.env.VUE_APP_DOMAIN;

const isTest = process.env.VUE_APP_MODE === "production" ? "" : "?env=test"

// GET
const collections = `${newsDomain}/v3/api/collections${isTest}`;
// GET
const transactions = `${newsDomain}/v3/api/transactions${isTest}`;
// GET
const tags = `${newsDomain}/v3/api/tags${isTest}`;
// GET
const collectionDetail = `${newsDomain}/v3/api/collection${isTest}`;
// GET
const itemDetail = `${newsDomain}/v3/api/item${isTest}`;

// GET
const whaleFeed = `${newsDomain}/v3/api/whale/feed${isTest}`;
// GET
const whales = `${newsDomain}/v3/api/whales${isTest}`;
// GET
const nBlueChips = `${newsDomain}/v3/api/n_blue_chips${isTest}`;
// GET
const accountWallet = (walletAddr: string) => `${newsDomain}/v3/api/account/${walletAddr}${isTest}`;
// GET
const accountWalletContracts = (walletAddr: string) => `${newsDomain}/v3/api/account/${walletAddr}/contracts${isTest}`;
// GET
const accountContracts = (walletAddr: string, contractAddr: string) => `${newsDomain}/v3/api/account/${walletAddr}/contracts/${contractAddr}/nfts${isTest}`;
// GET
const accountHistory = (walletAddr: string) => `${newsDomain}/v3/api/account/${walletAddr}/history${isTest}`;
// GET
const txsAccountWallet = (txHash: string, accountAddr: string) => `${newsDomain}/v3/api/txs/${txHash}/account/${accountAddr}${isTest}`;
// GET
const item = `${newsDomain}/v3/api/item`;


export {
  collections,
  transactions,
  tags,
  collectionDetail,
  itemDetail,
  whaleFeed,
  whales,
  nBlueChips,
  accountWallet,
  accountWalletContracts,
  accountContracts,
  accountHistory,
  txsAccountWallet,
  item,
}
