/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
import store from "@/store";
const formatTime = (time: number, toMonth = false) => {
	const minute = 1000 * 60;
	const hour = minute * 60;
	const day = hour * 24;
	const month = day * 30;
	const now = new Date().getTime();
	const diffValue = Math.abs(now - time);

	const minC = diffValue / minute;
	const hourC = diffValue / hour;
	const dayC = diffValue / day;
	const monC = diffValue / month;
	const result = {
		time: 0,
		type: "d",
		isAgo: true
	};
	if (now - time < 0) {
		result.isAgo = false;
	}
	if (toMonth) {
		if (monC >= 1) {
			result.time = Math.floor(monC);
			result.type = "M";
			return result;
		}
	}
	if (dayC >= 1) {
		result.time = Math.floor(dayC);
		result.type = "d";
	} else if (hourC >= 1 && hourC < 24) {
		result.time = Math.floor(hourC);
		result.type = "h";
	} else if (minC >= 1 && minC < 60) {
		result.time = Math.floor(minC);
		result.type = "m";
	} else if (diffValue >= 0 && diffValue <= minute) {
		result.time = Math.floor(diffValue/1000);
		result.type = "s";
	}

	return result;
};

const getDate = (time: string | number) => {
	const times = Number(time);
	const date = new Date(times);
	const y = date.getFullYear();
	const m = date.getMonth();
	const d = date.getDate();
	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	return d + " " + months[m];
};

const displayDateFormat = (time: number, t: any) => {
	const num = formatTime(time, true).time;
	const type = formatTime(time, true).type;
	const langKeyObj = {
		M: ["months ago", "month ago"],
		d: ["days ago", "day ago"],
		h: ["hours ago", "hour ago"],
		m: ["minutes ago", "minute ago"],
		s: ["seconds ago", "seconds ago"],
	};
	return store.getters.lang == "de"
		? t(langKeyObj[type][num > 1 ? 0 : 1]).replace("...", "" + num)
		: num + " " + t(langKeyObj[type][num > 1 ? 0 : 1]);
};
export {
	formatTime,
	getDate,
	displayDateFormat,
}
