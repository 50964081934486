
import { defineComponent } from "vue";
import noImg from "@/assets/feed/noImg.webp";
import { modifyImgSize } from "@/utils/imgSize";

export default defineComponent({
  name: "HeadIcon",
  props: {
    img: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 40, //48 40
    },
    borderWidth: {
      type: String,
      default: "border",
    },
    borderColor: {
      type: String,
      default: "border-neutral-44",
    },
  },
  setup() {
    return {
      noImg,
      modifyImgSize,
    };
  },
});
