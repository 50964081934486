import { resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_lazyload = _resolveDirective("lazyload")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", {
      style: {
        'flex-basis': `${_ctx.size}px`,
        width: `${_ctx.size}px`,
        height: `${_ctx.size}px`,
      },
      class: `
      flex-grow-0 flex-shrink-0
      flex
      items-center
      justify-center
      rounded-50
      bg-neutral-08
      ${_ctx.borderWidth} ${_ctx.borderColor} border-solid 
    `
    }, [
      _withDirectives(_createVNode("img", {
        class: `
        w-${_ctx.size === 40 ? 9 : _ctx.size === 32 ? 7 : _ctx.size / 4 - 2}
        h-${_ctx.size === 40 ? 9 : _ctx.size === 32 ? 7 : _ctx.size / 4 - 2}
        flex-grow-0 flex-shrink-0
        rounded-50
        object-cover
        overflow-hidden
        relative
        border-0 border-none
      `
      }, null, 2), [
        [_directive_lazyload, _ctx.modifyImgSize(_ctx.img, '_72x72')]
      ])
    ], 6)
  ]))
}